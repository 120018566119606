import Cookies from 'js-cookie'
import { utcRegexFormat } from 'utils/format'
import {
  ActivityListApi,
  AnalysisApi,
  AnnualEstimateApi,
  AuditLogApi,
  AuthenticationApi,
  BalanceResponsiblePartyApi,
  CompanyProfileApi,
  Configuration,
  ConfigurationApi,
  CorrectionCoefficientApi,
  DataImportApi,
  EstimateApi,
  EstimateOptionApi,
  EstimateStatusApi,
  FinalizedScheduleMetadataApi,
  ForecastSyncApi,
  ForecastValidationApi,
  InverterApi,
  InverterMonitoringApi,
  MekhDataApi,
  NeuralLearningApi,
  OperatorImportApi,
  PowerPlantApi,
  PowerPlantEmailSettingApi,
  PowerPlantGroupApi,
  PowerPlantGroupEstimateApi,
  PowerPlantGroupGlobalApi,
  PowerPlantGroupResponsiblePartyApi,
  PredictionReportageApi,
  ScheduleApi,
  ScheduleDataCorrectionOptionApi,
  ScheduleLogApi,
  ScheduleMetadataApi,
  ScheduleMetadataGroupApi,
  SolarPanelApi,
  SystemLogApi,
  UserApi
} from '@fiek-met/api-client'

// We have to parse date strings manually.
// https://github.com/OpenAPITools/openapi-generator/pull/5266
// --type-mappings=DateTime=Date doesn't work
export const transformObject = (key, val) => {
  if (typeof val === 'string' && utcRegexFormat.test(val)) {
    return new Date(val)
  }
  return val
}

const apiClient = new Configuration({
  basePath: process.env.REACT_APP_API_URL,
  accessToken: Cookies.get('fiek-met-token'),
  baseOptions: {
    withCredentials: false,
    transformResponse: (data) => {
      if (typeof data === 'string') {
        try {
          return JSON.parse(data, transformObject)
        } catch (e) {
          // console.error('ERROR', e, data)
        }
      }
      return data
    }
  }
})

export const removeApiCredentials = () => {
  apiClient.accessToken = null
}

export const activityListApi = new ActivityListApi(apiClient)
export const analysisApi = new AnalysisApi(apiClient)
export const annualEstimateApi = new AnnualEstimateApi(apiClient)
export const auditLogApi = new AuditLogApi(apiClient)
export const authenticationApi = new AuthenticationApi(apiClient)
export const balanceResponsibleApi = new BalanceResponsiblePartyApi(apiClient)
export const companyProfileApi = new CompanyProfileApi(apiClient)
export const configurationApi = new ConfigurationApi(apiClient)
export const correctionCoefficientApi = new CorrectionCoefficientApi(apiClient)
export const dataImportApi = new DataImportApi(apiClient)
export const estimateApi = new EstimateApi(apiClient)
export const estimateOptionApi = new EstimateOptionApi(apiClient)
export const estimateStatusApi = new EstimateStatusApi(apiClient)
export const finalizedScheduleMetadataApi = new FinalizedScheduleMetadataApi(
  apiClient
)
export const forecastSyncApi = new ForecastSyncApi(apiClient)
export const forecastValidationApi = new ForecastValidationApi(apiClient)
export const groupApi = new PowerPlantGroupApi(apiClient)
export const groupResponsibleApi = new PowerPlantGroupResponsiblePartyApi(
  apiClient
)
export const inverterApi = new InverterApi(apiClient)
export const inverterMonitoringApi = new InverterMonitoringApi(apiClient)
export const mekhDataApi = new MekhDataApi(apiClient)
export const neuralLearningApi = new NeuralLearningApi(apiClient)
export const operatorImportApi = new OperatorImportApi(apiClient)
export const powerPlantApi = new PowerPlantApi(apiClient)
export const powerPlantEmailSettingApi = new PowerPlantEmailSettingApi(apiClient)
export const powerPlantGroupEstimateApi = new PowerPlantGroupEstimateApi(
  apiClient
)
export const powerPlantGroupGlobalApi = new PowerPlantGroupGlobalApi(apiClient)
export const predictionReportageApi = new PredictionReportageApi(apiClient)
export const scheduleApi = new ScheduleApi(apiClient)
export const scheduleLogApi = new ScheduleLogApi(apiClient)
export const scheduleMetadataApi = new ScheduleMetadataApi(apiClient)
export const scheduleMetadataGroupApi = new ScheduleMetadataGroupApi(apiClient)
export const solarPanelApi = new SolarPanelApi(apiClient)
export const systemLogApi = new SystemLogApi(apiClient)
export const userApi = new UserApi(apiClient)
export const scheduleDataCorrectionOptionApi = new ScheduleDataCorrectionOptionApi(
  apiClient
)
