import { createSlice } from '@reduxjs/toolkit'
import { ModalStatus } from 'models/enum'
import { powerPlantEmailSettingApi } from 'services/api'
import { getToday } from 'utils/date'

const initialState = {
  powerPlantEmailSettingList: [],
  powerPlantEmailSettingListLoading: false,

  createPowerPlantEmailSetting: {},
  powerPlantEmailSettingLoading: false,

  powerPlantEmailSettingById: {},
  powerPlantEmailSettingByIdLoading: false,

  createPowerPlantEmailSettings: {},
  createPowerPlantEmailSettingsLoading: false,

  conflictedPowerPlants: {},
  savedPowerPlantEmailSettings: {},
  selectedPowerPlantEmailSetting: [],

  powerPlantEmailSettingModalStatus: ModalStatus.Hidden,
  conflictCheckingModalStatus: ModalStatus.Hidden,
  error: null
}

const powerPlantEmailSettingSlice = createSlice({
  name: 'powerPlantEmailSetting',
  initialState,
  reducers: {
    getPowerPlantEmailSettingListStart (state) {
      state.error = null
      state.powerPlantEmailSettingListLoading = true
    },
    getPowerPlantEmailSettingListSuccess (state, { payload }) {
      state.powerPlantEmailSettingList = payload
      state.error = null
      state.powerPlantEmailSettingListLoading = false
    },
    getPowerPlantEmailSettingListFailed (state, { payload }) {
      state.powerPlantEmailSettingList = []
      state.error = payload.error
      state.powerPlantEmailSettingListLoading = false
    },
    createPowerPlantEmailSettingStart (state) {
      state.error = null
      state.powerPlantEmailSettingLoading = true
    },
    createPowerPlantEmailSettingSuccess (state) {
      state.error = null
      state.createPowerPlantEmailSetting = {}
      state.powerPlantEmailSettingLoading = false
    },
    createPowerPlantEmailSettingFailed (state, { payload }) {
      state.error = payload.error
      state.createPowerPlantEmailSetting = {}
      state.powerPlantEmailSettingLoading = false
    },
    getPowerPlantEmailSettingByIdStart (state) {
      state.error = null
      state.powerPlantEmailSettingByIdLoading = true
    },
    getPowerPlantEmailSettingByIdSuccess (state, { payload }) {
      state.powerPlantEmailSettingById = payload
      state.error = null
      state.powerPlantEmailSettingByIdLoading = false
    },
    getPowerPlantEmailSettingByIdFailed (state, { payload }) {
      state.powerPlantEmailSettingById = {}
      state.error = payload.error
      state.powerPlantEmailSettingByIdLoading = false
    },
    updatePowerPlantEmailSettingStart (state) {
      state.error = null
      state.powerPlantEmailSettingByIdLoading = true
    },
    updatePowerPlantEmailSettingSuccess (state) {
      state.powerPlantEmailSettingById = {}
      state.error = null
      state.powerPlantEmailSettingByIdLoading = false
    },
    updatePowerPlantEmailSettingFailed (state, { payload }) {
      state.powerPlantEmailSettingById = {}
      state.error = payload.error
      state.powerPlantEmailSettingByIdLoading = false
    },
    deletePowerPlantEmailSettingSuccess (state) {
      state.error = null
      state.selectedPowerPlantEmailSetting = []
    },
    deletePowerPlantEmailSettingFailed (state, { payload }) {
      state.error = payload.error
    },
    createPowerPlantEmailSettingsStart (state) {
      state.error = null
      state.createPowerPlantEmailSettingsLoading = true
    },
    createPowerPlantEmailSettingsSuccess (state) {
      state.createPowerPlantEmailSettings = {}
      state.error = null
      state.powerPlantEmailSettingModalStatus = ModalStatus.Hidden
      state.createPowerPlantEmailSettingsLoading = false
    },
    createPowerPlantEmailSettingsFailed (state, { payload }) {
      state.createPowerPlantEmailSettings = {}
      state.error = payload.error
      state.createPowerPlantEmailSettingsLoading = false
    },
    deletePowerPlantEmailSettingsSuccess (state) {
      state.error = null
    },
    deletePowerPlantEmailSettingsFailed (state, { payload }) {
      state.error = payload.error
    },

    setPowerPlantEmailSettingModalStatus (state, { payload }) {
      state.powerPlantEmailSettingModalStatus = payload
    },
    setConflictCheckingModalStatus (state, { payload }) {
      state.conflictCheckingModalStatus = payload
    },
    setConflictedPowerPlants (state, { payload }) {
      state.conflictedPowerPlants = payload
    },
    setPowerPlantEmailSettings (state, { payload }) {
      state.savedPowerPlantEmailSettings = payload
    },
    setSelectedPowerPlantEmailSetting (state, { payload }) {
      state.selectedPowerPlantEmailSetting = payload
    },
    clear (state) {
      state.powerPlantEmailSettingList = []
      state.powerPlantEmailSettingById = {}
      state.savedPowerPlantEmailSettings = {}
      state.selectedPowerPlantEmailSetting = []
      state.powerPlantEmailSettingModalStatus = ModalStatus.Hidden
      state.conflictCheckingModalStatus = ModalStatus.Hidden
      state.error = null
    }
  }
})

const {
  getPowerPlantEmailSettingListStart,
  getPowerPlantEmailSettingListSuccess,
  getPowerPlantEmailSettingListFailed,

  createPowerPlantEmailSettingStart,
  createPowerPlantEmailSettingSuccess,
  createPowerPlantEmailSettingFailed,

  getPowerPlantEmailSettingByIdStart,
  getPowerPlantEmailSettingByIdSuccess,
  getPowerPlantEmailSettingByIdFailed,

  updatePowerPlantEmailSettingStart,
  updatePowerPlantEmailSettingSuccess,
  updatePowerPlantEmailSettingFailed,

  deletePowerPlantEmailSettingSuccess,
  deletePowerPlantEmailSettingFailed,

  createPowerPlantEmailSettingsStart,
  createPowerPlantEmailSettingsSuccess,
  createPowerPlantEmailSettingsFailed,

  deletePowerPlantEmailSettingsSuccess,
  deletePowerPlantEmailSettingsFailed,

  setPowerPlantEmailSettingModalStatus,
  setConflictCheckingModalStatus,
  setConflictedPowerPlants,
  setPowerPlantEmailSettings,
  setSelectedPowerPlantEmailSetting,
  clear
} = powerPlantEmailSettingSlice.actions

const getPowerPlantEmailSettingList = () => async dispatch => {
  try {
    dispatch(getPowerPlantEmailSettingListStart())
    const powerPlantEmailSettingList = await powerPlantEmailSettingApi.getAllPowerPlantEmailSetting()
    const onlyNotExpiredEmailSettings =
      powerPlantEmailSettingList.filter(ppes =>
        getToday().isBefore(ppes.to) ||
        ppes.to === null)
    dispatch(getPowerPlantEmailSettingListSuccess(onlyNotExpiredEmailSettings))
  } catch (error) {
    dispatch(getPowerPlantEmailSettingListFailed({ error }))
  }
}

const createPowerPlantEmailSetting = (createPowerPlantEmailSettingDto) => async dispatch => {
  try {
    dispatch(createPowerPlantEmailSettingStart())
    await powerPlantEmailSettingApi.createPowerPlantEmailSetting({ createPowerPlantEmailSettingDto })
    dispatch(createPowerPlantEmailSettingSuccess())
    dispatch(getPowerPlantEmailSettingList())
  } catch (error) {
    dispatch(createPowerPlantEmailSettingFailed({ error }))
  }
}

const getPowerPlantEmailSettingById = (id) => async dispatch => {
  try {
    dispatch(getPowerPlantEmailSettingByIdStart())
    const powerPlantEmailSettingById = await powerPlantEmailSettingApi.getPowerPlantEmailSetting({ id })
    dispatch(getPowerPlantEmailSettingByIdSuccess(powerPlantEmailSettingById))
  } catch (error) {
    dispatch(getPowerPlantEmailSettingByIdFailed({ error }))
  }
}

const updatePowerPlantEmailSetting = (id, updatePowerPlantEmailSettingDto) => async dispatch => {
  try {
    dispatch(updatePowerPlantEmailSettingStart())
    await powerPlantEmailSettingApi.editPowerPlantEmailSetting({ id, updatePowerPlantEmailSettingDto })
    dispatch(updatePowerPlantEmailSettingSuccess())
    dispatch(getPowerPlantEmailSettingList())
  } catch (error) {
    dispatch(updatePowerPlantEmailSettingFailed({ error }))
  }
}

const deletePowerPlantEmailSetting = (id) => async dispatch => {
  try {
    await powerPlantEmailSettingApi.deletePowerPlantEmailSetting({ id })
    dispatch(deletePowerPlantEmailSettingSuccess())
    dispatch(setSelectedPowerPlantEmailSetting([]))
    dispatch(getPowerPlantEmailSettingList())
  } catch (error) {
    dispatch(deletePowerPlantEmailSettingFailed({ error }))
  }
}

const createPowerPlantEmailSettings = (createPowerPlantEmailSettingsDto) => async dispatch => {
  try {
    dispatch(createPowerPlantEmailSettingsStart())
    await powerPlantEmailSettingApi.createPowerPlantEmailSettings({ createPowerPlantEmailSettingsDto })
    dispatch(createPowerPlantEmailSettingsSuccess())
    dispatch(getPowerPlantEmailSettingList())
  } catch (error) {
    dispatch(createPowerPlantEmailSettingsFailed({ error }))
  }
}

const deletePowerPlantEmailSettings = (ids) => async dispatch => {
  try {
    await powerPlantEmailSettingApi.deletePowerPlantEmailSettings({ ids })
    dispatch(deletePowerPlantEmailSettingsSuccess())
    dispatch(getPowerPlantEmailSettingList())
  } catch (error) {
    dispatch(deletePowerPlantEmailSettingsFailed({ error }))
  }
}

export {
  getPowerPlantEmailSettingList,
  createPowerPlantEmailSetting,
  getPowerPlantEmailSettingById,
  updatePowerPlantEmailSetting,
  deletePowerPlantEmailSetting,
  createPowerPlantEmailSettings,
  deletePowerPlantEmailSettings,
  setConflictedPowerPlants,
  setPowerPlantEmailSettingModalStatus,
  setConflictCheckingModalStatus,
  setPowerPlantEmailSettings,
  setSelectedPowerPlantEmailSetting,
  clear
}

export default powerPlantEmailSettingSlice.reducer
